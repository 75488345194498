import COS from 'cos-js-sdk-v5'

let cos = new COS({
	getAuthorization: function(params, callback) {
		var authorization = COS.getAuthorization({
			SecretId: "AKID82hLp8DSKwIuKiIoZ37sMbeR2C7lRTUK",
			SecretKey: "iFrDI7hiiR0xfhVCeqwm47tPIwKaEhvu",
			Method: params.Method,
			Key: params.Key
		});
		callback(authorization);
	}
});

export const uploadFile = function(filepath, dirname, type) {
	return new Promise((resolve) => {
		//上传文件至腾讯云
		cos.putObject({
			Bucket: "jmstatic-1255497916",
			Region: "ap-shanghai",
			Key: createFileName(dirname, type),
			Body: filepath,
			StorageClass: 'STANDARD',
			onProgress: function(progressData) {
				console.log('onProgress' + JSON.stringify(progressData));
			}
		}, function(err, data) {
			if (err) {
				resolve({
					status: "fail",
					url: filepath
				})
			} else {
				resolve({
					status: "success",
					url: changeImagePathName(data.Location)
				})
			}
		});
	})
};

//生成文件名
let createFileName = function(dirname, type = 'jpg') {
	//获取时间
	let date = new Date();
	let year = date.getFullYear();
	let month = date.getMonth() + 1;
	let day = date.getDate();
	let datetem = date.getTime().toString()
	datetem = datetem.substring(datetem.length-10)
	month = month < 10 ? "0" + month : month;
	day = day < 10 ? "0" + day : day;
	//产生4位随机数
	let randomNum = '';
	for (let i = 0; i < 4; i++) {
		randomNum += Math.floor(Math.random() * 10)
	}
	let alldirname = dirname + "/" + year + month + day + "/" + "t" + datetem + '_' + randomNum + '.' + type;
	return alldirname;
};

//修改图片路径名
let changeImagePathName = function(oldpath) {
	return "https://img.jimeimadou.com".concat(oldpath.substr(oldpath.indexOf('/')))
};
